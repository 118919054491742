<template>
    <div>
        <v-title title="Edit Product Feed"></v-title>
        <title-box>
            <div class="row">
                <div class="col col-8">
                    <h1>Edit Product Feed</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid">
            <div class="mt-4">
                <div>
                    <product-feed-content :is-editing="true"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProductFeedContent from '@/components/elements/product-feed/ProductFeedContent'

export default {
    name: 'EditProductFeed',
    components: { ProductFeedContent },
}
</script>
